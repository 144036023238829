import { createTheme } from '@mui/material/styles';

// Extend the theme types
declare module '@mui/material/styles' {
  interface Theme {
    interviewAcademy: {
      white: string;
      grayLight: string;
      black: string;
      primaryDark: string;
      primaryLight: string;
      secondaryDark: string;
      secondaryLight: string;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    interviewAcademy?: {
      white?: string;
      grayLight?: string;
      black?: string;
      primaryDark?: string;
      primaryLight?: string;
      secondaryDark?: string;
      secondaryLight?: string;
    };
  }
}

// Layout constants
export const DRAWER_WIDTH = 216;
export const SIDEBAR_WIDTH_OPEN = 200;
export const SIDEBAR_WIDTH_CLOSED = 64;

export const theme = createTheme({
  palette: {
    primary: { main: '#528075' },
    info: { main: '#FFFFFF' },
  },
  typography: {
    fontFamily: 'DM Sans',
  },
  interviewAcademy: {
    white: '#ffffff',
    grayLight: '#f5f5f5',
    black: '#000000',
    primaryDark: '#40a6a0',
    primaryLight: '#d9f0ef',
    secondaryDark: '#5147b4',
    secondaryLight: '#edecf7',
  },
});
