import { ExpandCircleDownOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { tokenState } from '../states/auth';
import { uiState } from '../states/uiState';
import { SIDEBAR_WIDTH_CLOSED, SIDEBAR_WIDTH_OPEN } from '../styles/theme';
import { Breadcrumb } from './Breadcrumb';
import { Footer } from './Footer';
import Header from './Header';
import SideBar from './SideBar';
import { SubscriptionBanner } from './SubscriptionBanner';

type Props = PropsWithChildren<{
  header?: ReactNode;
  subHeader?: ReactNode;
  footer?: ReactNode;
  showHeaderButtons?: boolean;
  showBackButton?: boolean;
  showSubscriptionBanner?: boolean;
  hideMenu?: boolean;
  backgroundColor?: string;
  breadcrumb?: Array<{ title: string; destination: string }>;
}>;

export function MainLayout({
  children,
  footer,
  header,
  hideMenu,
  subHeader,
  showSubscriptionBanner,
  backgroundColor,
  breadcrumb,
}: Props) {
  const [ui, setUi] = useRecoilState(uiState);
  const token = useRecoilValue(tokenState);

  const { menuOpen } = ui;
  const setMenuOpen = (menuOpen: boolean) => setUi({ ...ui, menuOpen });

  const isMobile = window.innerWidth <= 768;

  // Add effect to handle background color for html and body
  useEffect(() => {
    if (backgroundColor) {
      // Save original background colors
      const originalHtmlBg = document.documentElement.style.backgroundColor;
      const originalBodyBg = document.body.style.backgroundColor;

      // Apply background color to html and body
      document.documentElement.style.backgroundColor = backgroundColor;
      document.body.style.backgroundColor = backgroundColor;

      // Cleanup function to restore original background colors
      return () => {
        document.documentElement.style.backgroundColor = originalHtmlBg;
        document.body.style.backgroundColor = originalBodyBg;
      };
    }

    // Return a no-op cleanup function for the case when backgroundColor is not provided
    return () => {};
  }, [backgroundColor]);

  return (
    <div
      className="min-h-screen"
      style={{ backgroundColor: backgroundColor || '' }}
    >
      {token && !hideMenu && (
        <div
          className={`fixed top-0 left-0 bottom-0 transition-all z-30 h-dvh ${
            isMobile && menuOpen && 'shadow-sideBar'
          }`}
          style={{
            width: menuOpen ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH_CLOSED,
          }}
        >
          <IconButton
            className="text-white !absolute !-right-5 !top-4"
            onClick={() => setMenuOpen(!menuOpen)}
            aria-label="delete"
          >
            <ExpandCircleDownOutlined
              className={`origin-center transition-transform bg-white text-textBorder rounded-full  ${
                !menuOpen ? '-rotate-90' : 'rotate-90'
              } `}
            />
          </IconButton>
          <SideBar menuOpen={menuOpen} />
        </div>
      )}

      <div className="flex">
        <div
          className="flex flex-col px-8 transition-all"
          style={{
            marginLeft:
              token && !hideMenu
                ? menuOpen
                  ? SIDEBAR_WIDTH_OPEN
                  : SIDEBAR_WIDTH_CLOSED
                : 0,
            width:
              token && !hideMenu
                ? `calc(100% - ${
                    menuOpen ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH_CLOSED
                  }px)`
                : '100%',
          }}
        >
          <div>
            {!token && <Header />}
            {showSubscriptionBanner && <SubscriptionBanner />}
            {header}
            {subHeader}
          </div>
          <Breadcrumb links={breadcrumb} />
          <div className="grow">{children}</div>
          {footer && (
            <div
              className={`fixed bottom-0 w-full right-0 ${
                menuOpen ? 'pl-64' : 'pl-16'
              }`}
            >
              <Footer>{footer}</Footer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
