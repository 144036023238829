import { AxiosError } from 'axios';
import moment from 'moment';
import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { useRecoilValue } from 'recoil';
import { User } from '../data/user';
import { clientState } from '../states/auth';

function useFetchMe(
  options?: UseQueryOptions<{ data: User; success: boolean }, AxiosError>
) {
  const client = useRecoilValue(
    clientState({
      contentType: 'application/json',
    })
  );

  const result = useQuery<{ data: User; success: boolean }, AxiosError>(
    ['/users/me'],
    () => client.get('/users/me').then(r => r.data),
    {
      ...options,
      onSuccess(...args) {
        options?.onSuccess?.(...args);
      },
      onError(e) {
        console.error(e);
      },
    }
  );

  return result;
}

export function useMe() {
  const { data, isLoading, isSuccess, refetch: refetchMe } = useFetchMe();

  const trialRemainingDays = useMemo(() => {
    if (data?.data != null && data.data.isFree) {
      const trialExpiresAt = new Date(data.data.trialExpiresAt);
      const currentDate = new Date();
      const daysRemaining = Math.max(
        moment(trialExpiresAt).diff(moment(currentDate), 'days'),
        0
      );
      return daysRemaining;
    }
    return 0;
  }, [data?.data]);

  // const canPractice = false;
  const canPractice = useMemo(
    () => !data?.data.isFree || trialRemainingDays > 0,
    [data?.data.isFree, trialRemainingDays]
  );

  // const isFree = true;
  const isFree = useMemo(() => data?.data.isFree, [data?.data.isFree]);

  const isAdmin = useMemo(
    () => data?.data.role?.permissions.includes('accessDashboard'),
    [data?.data.role]
  );

  return {
    user: data?.data,
    refetchMe,
    isAdmin,
    isFree,
    isLoading,
    isSuccess,
    trialRemainingDays,
    canPractice,
  };
}
