import { Box, Button } from '@mui/material';
import { APP_PATHS } from '../routes/paths';

interface BreadcrumbLink {
  title: string;
  destination: string;
}

interface BreadcrumbProps {
  links?: BreadcrumbLink[];
}

const homeLink = { title: 'Home', destination: APP_PATHS.home };

export function Breadcrumb({ links }: BreadcrumbProps) {
  if (!links) return null;

  const breadcrumbLinks = [homeLink, ...links];

  return (
    <Box
      sx={{
        height: '48px',
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        paddingRight: '16px',
        boxSizing: 'border-box',
      }}
    >
      {breadcrumbLinks.map((link, index) => (
        <Box key={link.title} sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => {
              if (index !== breadcrumbLinks.length - 1) {
                window.location.href = link.destination;
              }
            }}
            sx={{
              background: 'none',
              border: 'none',
              padding: 0,
              color: index === breadcrumbLinks.length - 1 ? 'black' : '#595D62',
              cursor:
                index === breadcrumbLinks.length - 1 ? 'default' : 'pointer',
              fontSize: 'inherit',
              fontFamily: 'inherit',
              minWidth: 'auto',
              '&:hover': {
                background: 'none',
              },
              textTransform: 'none',
            }}
          >
            {link.title}
          </Button>
          {index < breadcrumbLinks.length - 1 && (
            <Box component="span" sx={{ margin: '0 8px', color: '#595D62' }}>
              /
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}
