class ContantValues {
  TEXTFIELD_ONE_ROW_HEIGHT = 23;
  API_BASE_URLS = {
    dev: 'https://pi-backend-dev-401883a6ed36.herokuapp.com',
    stage: 'https://pi-backend-stage-983c522c7c19.herokuapp.com',
    production: 'https://pi-backend-prod-cd0ba6433021.herokuapp.com',
  };
  SOCKET_BASE_URLS = {
    dev: 'https://talkwise-backend-dev.herokuapp.com',
    stage: 'https://talkwise-backend-stage.herokuapp.com',
    production: 'https://talkwise-backend-prod.herokuapp.com',
  };
  POSTHOG_FEATURE_FLAG = {
    trialDays: 'trial-days-comparison',
    subscriptionPricing: 'subscription-pricing-comparison',
  };
}

export const Constants = new ContantValues();

export const videoContent = [
  {
    title: 'Clarifying Questions',
    url: 'https://www.youtube.com/watch?v=_jRWFxmVCtM',
    summary:
      'Learn to craft strategic clarifying questions for hypothetical scenarios using the CFAS method.',
    transcript:
      'Lorem ipsum odor amet, consectetuer adipiscing elit. At risus interdum cras hendrerit phasellus aliquam lorem morbi. Ante per bibendum consequat augue varius, elementum nostra. Porta leo eget dolor maximus enim. Netus facilisi aliquet sit velit condimentum venenatis nec platea convallis. Quam cursus platea elit mollis molestie.',
  },
  {
    title: 'Frameworks',
    url: 'https://www.youtube.com/watch?v=S8_m-xl_ELA',
    summary:
      'Craft clear, concise frameworks: Showcase your structured thinking in hypothetical scenarios.',
    transcript:
      'Lacinia dolor dis senectus risus ultrices, tellus justo. Tincidunt primis pulvinar odio gravida massa faucibus. Commodo porta nec suspendisse molestie nec rutrum tempor tempus tristique. Aptent maecenas facilisi massa auctor taciti morbi varius faucibus sodales. Natoque litora fames sapien orci vulputate donec. Rutrum luctus placerat suscipit tellus hendrerit pellentesque lacus non. Dui orci cubilia; duis erat maximus nibh sem.',
  },
  {
    title: 'Assumptions',
    url: 'https://www.youtube.com/watch?v=YOrFpT8BChM',
    summary:
      'Learn to create powerful, visual assumptions that make your hypothetical answers memorable.',
    transcript:
      'Torquent sollicitudin tristique iaculis accumsan justo libero. Leo felis commodo cubilia maecenas; urna pharetra pellentesque. Accumsan duis phasellus neque consectetur sodales fringilla rhoncus primis. Turpis etiam viverra porttitor hendrerit sed nibh, condimentum diam. Suscipit dictum convallis iaculis nunc pharetra mattis integer? Interdum odio praesent elementum netus habitasse mollis. Mollis mus dapibus finibus et praesent interdum donec. Dui dictum sapien lacus lacus dignissim nostra felis.',
  },
  {
    title: 'Solutions',
    url: 'https://www.youtube.com/watch?v=HEd8ASlp_m4',
    summary:
      'Transform abstract questions into concrete solutions: Your key to interview success.',
    transcript:
      'Ut dignissim ac commodo pharetra in quis elit leo. Lorem efficitur ante ullamcorper vehicula convallis tempor auctor sodales. Eros hendrerit ultrices morbi urna ultrices aenean. Justo gravida senectus turpis faucibus, vestibulum quam. Cursus himenaeos aliquet ultricies at massa cubilia egestas. Lectus porttitor accumsan vulputate maximus gravida egestas tortor semper. Posuere malesuada primis aliquam habitasse torquent vivamus amet. Metus fusce habitasse suscipit himenaeos curae aptent. Posuere tempor hac; class netus quisque massa id. Penatibus conubia odio sodales sit quis.',
  },
  {
    title: 'Transitions',
    url: 'https://www.youtube.com/watch?v=B23KhEgrzGM',
    summary:
      'Elevate your responses: Use transitions to maintain control and showcase your problem-solving skills while keeping your interviewer engaged.',
    transcript:
      'Ornare amet faucibus sagittis feugiat suspendisse blandit rutrum iaculis. Hendrerit lacus primis habitasse; malesuada nisi inceptos. Diam urna purus bibendum class adipiscing leo sed fermentum. Quis semper nisi dignissim feugiat massa sollicitudin aptent posuere bibendum. Nulla quisque semper non conubia habitasse mattis justo volutpat. Fusce himenaeos tempor vivamus diam ipsum.',
  },
];
